import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../../../config/index';
import { urlConstants } from '../../../constants/urlConstants';

const baseQuery = fetchBaseQuery({
    baseUrl: config().BASE_URL,
    prepareHeaders: (headers) => {
        const token = localStorage.getItem('cpb-token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 401) {
        localStorage.clear();
        window.location.href = '/';
        }
    return result;
};

export const UserApi = createApi({
    reducerPath: 'UserApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['user'],
    endpoints: (builder) => ({
        getUsers: builder.query<any, any>({
            query: (searchPrams:any) => ({
                url: urlConstants.USERLIST,
                method: 'GET',
                params:{
                    page:searchPrams?.currentPage,
                    searchString:searchPrams?.searchString
                }
            }),
        }),
        getSingleUser: builder.query<any, string>({
            query: (userId) => `${urlConstants.USERLIST}/${userId}`,
        }),
        updateUser: builder.mutation<any, { userId: string, body: any }>({
            query: ({ userId, body }) => ({
                url: `${urlConstants.USERLIST}/${userId}`,
                method: 'PUT',
                body: body,
            }),
        }),
        createUser: builder.mutation<any, any >({
            query: ( createUser: any) => ({
                url: urlConstants.USERLIST,
                method: 'POST',
                body: createUser,
            }),
        }),
        deleteUser: builder.mutation<any,any>({
            query: (id: any) => ({
                url: `${urlConstants.USERLIST}/${id}`,
                method: 'DELETE',
            }),
        }),
        // admin
        getAdminUser: builder.query<any, any>({
            query: (apiParams:any) => ({
                url: urlConstants.ADMIN_USER_LIST,
                method: 'GET',
                params:{
                    page:apiParams?.currentPage,
                    searchString:apiParams?.searchString
                }
            }),
        }),
        createAdminUser: builder.mutation<any, any>({
            query: (createAdminUser: any) => ({
                url: urlConstants.ADMIN_USER_LIST,
                method: 'POST',
                body: createAdminUser,
            }),
        }),
        editAdminUser: builder.mutation<any, any>({
            query: ({ userId, body }) => ({
                url: `${urlConstants.ADMIN_USER_LIST}/${userId}`,
                method: 'PUT',
                body: body,
            }),
        }),
        deleteAdmin: builder.mutation<any,any>({
            query: (id: any) => ({
                url: `${urlConstants.ADMIN_USER_LIST}/${id}`,
                method: 'DELETE',
            }),
        }),
        // contact
        getContacts: builder.query<any, any>({
            query: (apiParams: any) => {
                const params: any = {
                    page: apiParams?.page,
                    searchString: apiParams?.search,
                    ...(apiParams?.createdBy && { createdBy: apiParams?.createdBy })
                };

                if (apiParams?.profileType) {
                    params.profileType = apiParams.profileType;
                }

                if (apiParams?.location) {
                    params.location = apiParams.location;
                }

                return {
                    url: urlConstants.ADMIN_USER_CONTACT,
                    method: 'GET',
                    params
                };
            }
        }),
        createContact: builder.mutation<any, any>({
            query: (formDataPayload: any) => ({
                url: urlConstants.ADMIN_USER_CONTACT,
                method: 'POST',
                body: formDataPayload,
            }),
        }),
        getCreatedByOptions: builder.query<any, void>({
            query: () => ({
                url: urlConstants.USER_DROP_DOWN,
                method: 'GET',
            }),
        }),
        getSingelContact: builder.query<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.ADMIN_USER_CONTACT}/${id}`
            }),
        }),
        editContact: builder.mutation<any, { id: any; formData: any }>({
            query: ({ id, formData }) => ({
                url: `${urlConstants.ADMIN_USER_CONTACT}/${id}`,
                method: 'PUT',
                body: formData,
            }),
        }),
        getSingleAdmin: builder.query<any, any>({
            query: (id: any) => ({
                url: `${urlConstants.ADMIN_USER_LIST}/${id}`
            }),
        }),
        deleteUserContact: builder.mutation<any,any>({
            query: (id: any) => ({
                url: `${urlConstants.ADMIN_USER_CONTACT}/${id}`,
                method: 'DELETE',
            }),
        }),
        // lable
        getContactLabel: builder.query<any, any>({
            query: (apiParams:any) => ({
                url: urlConstants.CONTACT_LABEL,
                method: 'GET',
                params:{
                    page:apiParams?.currentPage,
                    searchString:apiParams?.searchString
                }
            }),
        }),
        getSingleUserContactLabel: builder.query({
            query: (id) => `${urlConstants.CONTACT_LABEL}/${id}`,
        }),
        updateUserContactLabel: builder.mutation({
            query: ({ labelId, body }) => ({
                url: `${urlConstants.CONTACT_LABEL}/${labelId}`,
                method: 'PUT',
                body,
            }),
        }),
        createUserContactLabel: builder.mutation({
            query: (body) => ({
                url: urlConstants.CONTACT_LABEL,
                method: 'POST',
                body,
            }),
        }),
        deleteUserLabel: builder.mutation<any,any>({
            query: (id: any) => ({
                url: `${urlConstants.CONTACT_LABEL}/${id}`,
                method: 'DELETE',
            }),
        }),
        getContactDropdown: builder.query<any, void>({
            query: () => ({
                url: urlConstants.CONTACT_LABEL_DROPDOWN,
            }),
        }),
        // reset password
        sendOtp: builder.mutation<any, any>({
            query: (data) => ({
                url: urlConstants.FORGOT_PASSWORD_SEND_OTP,
                method: 'POST',
                body: data
            }),
        }),
        verifyOtp: builder.mutation<any, any>({
            query: (data) => ({
                url: urlConstants.FORGOT_PASSWORD_VERIFY_OTP,
                method: 'POST',
                body: data
            }),
        }),
        resetPassword: builder.mutation<any, any>({
            query: (data) => ({
                url: urlConstants.FORGOT_PASSWORD_RESET_PASSWORD,
                method: 'POST',
                body: data
            }),
        }),
        // Profile
        getContactProfile: builder.query<any, any>({
            query: (apiParams:any) => ({
                url: urlConstants.CONTACT_PROFILE,
                method: 'GET',
                params:{
                    page:apiParams?.currentPage,
                    searchString:apiParams?.searchString
                }
            }),
        }),
        getSingleContactProfile: builder.query({
            query: (id) => `${urlConstants.CONTACT_PROFILE}/${id}`,
        }),
        updateContactProfile: builder.mutation({
            query: ({ profileId, body }) => ({
                url: `${urlConstants.CONTACT_PROFILE}/${profileId}`,
                method: 'PUT',
                body,
            }),
        }),
        createContactProfile: builder.mutation({
            query: (body) => ({
                url: urlConstants.CONTACT_PROFILE,
                method: 'POST',
                body,
            }),
        }),
        deleteContactProfile: builder.mutation<any,any>({
            query: (id: any) => ({
                url: `${urlConstants.CONTACT_PROFILE}/${id}`,
                method: 'DELETE',
            }),
        }),
        getContactProfileDropdown: builder.query<any, void>({
            query: () => ({
                url: urlConstants.CONTACT_PROFIEL_DROPDOWN,
            }),
        }),
        // location
        getContactLocationSuggestions: builder.query<any, string>({
            query: (value: string) => ({
                url: urlConstants.CONTACT_LOCATION,
                method: 'GET',
                params: {
                    value: value,
                    limit: 10
                }
            }),
        }),
        // change user password
        changeUserPassword: builder.mutation({
            query: (body) => ({
                url: urlConstants.USER_PASSWORD_CHANGE,
                method: 'POST',
                body
            }),
        }),
        // dashboard
        getAdminDashboard: builder.query<any, void>({
            query: () => ({
                url: urlConstants.ADMIN_DASHBOARD,
            }),
        }),
        // Contact Visibility
        getContactVisibility: builder.query<any, void>({
            query: () => ({
                url: urlConstants.CONTACT_VISIBILITY,
            }),
        })
    }),
});

export const {
    useGetUsersQuery,
    useGetSingleUserQuery,
    useUpdateUserMutation,
    useCreateUserMutation,
    useDeleteUserMutation,

    useGetAdminUserQuery,
    useCreateAdminUserMutation,
    useDeleteAdminMutation,

    useGetContactsQuery,
    useCreateContactMutation,
    useGetCreatedByOptionsQuery,
    useGetSingelContactQuery,
    useEditContactMutation,
    useGetSingleAdminQuery,
    useEditAdminUserMutation,
    useDeleteUserContactMutation,

    useGetContactLabelQuery,
    useGetSingleUserContactLabelQuery,
    useUpdateUserContactLabelMutation,
    useCreateUserContactLabelMutation,
    useDeleteUserLabelMutation,
    useGetContactDropdownQuery,

    useSendOtpMutation,
    useVerifyOtpMutation,
    useResetPasswordMutation,

    useCreateContactProfileMutation,
    useGetContactProfileQuery,
    useGetContactProfileDropdownQuery,
    useDeleteContactProfileMutation,
    useUpdateContactProfileMutation,
    useGetSingleContactProfileQuery,

    useGetContactLocationSuggestionsQuery,
    useGetAdminDashboardQuery,

    useChangeUserPasswordMutation,
    useGetContactVisibilityQuery
} = UserApi;

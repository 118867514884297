import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AlertMessageState = {
    messageNotify: boolean;
    messageInfo: string;
    messageType: string;
}

const initialState: AlertMessageState = {
    messageNotify: false,
    messageInfo: "",
    messageType: "",
}

const AlertMessageSlice = createSlice({
    name: 'AlertMessage',
    initialState,
    reducers: {
        setMessageInfo: (
            state: AlertMessageState,
            action: PayloadAction<string>
        ) => {
            state.messageInfo = action.payload;
        },
        setMessageNotify: (
            state: AlertMessageState,
            action: PayloadAction<boolean>
        ) => {
            state.messageNotify = action.payload;
        },
        setMessageType: (
            state: AlertMessageState,
            action: PayloadAction<string>
        ) => {
            state.messageType = action.payload;
        }
    }
})

export default AlertMessageSlice.reducer;

export const {
    setMessageNotify,
    setMessageType,
    setMessageInfo,
} = AlertMessageSlice.actions;

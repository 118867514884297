import { configureStore, combineReducers } from '@reduxjs/toolkit';
import errorMessageReducer from '../reducer/errorMessageReducer';
import { loginApi } from '../../services/apiServices/login/login';
import { UserApi } from '../../services/apiServices/casa_admin/casaAdminApis';
import alertReducer from '../reducer/alertReducer';


const rootReducers = combineReducers({
    ErrorMessageReducer: errorMessageReducer,
    alertReducer: alertReducer,
    loginApi: loginApi.reducer,
    UserApi: UserApi.reducer
});

const store = configureStore({
    reducer: rootReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([
        loginApi.middleware,
        UserApi.middleware
    ])
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

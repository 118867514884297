import React, { Suspense } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './scss/common.scss';
import { Routes, Route } from "react-router-dom";
import Loading from './pages/miscellaneous/loading';
import { PATH } from './constants/path';
import 'react-responsive-pagination/themes/classic.css';
import { useAppSelector } from './base/hooks/hooks';
import CustomAlert from './pages/common/customAlert/customAlert';

const Header = React.lazy(() => import('./layouts/index'))
const Login = React.lazy(() => import('./pages/login/login'));
const Home = React.lazy(() => import('./pages/home/index'));
const UserCreation = React.lazy(() => import('./pages/userCreation/userCreation'));
const UserCreationAdd = React.lazy(() => import('./pages/userCreation/userCreationAdd/userCreationAdd'));
const NotFound = React.lazy(() => import('./pages/miscellaneous/notFound'))
const UserListPage = React.lazy(() => import('./pages/AdminListPage/AdminListPage'))
const UserListPageAdd = React.lazy(() => import('./pages/AdminListPage/AdminListPageAdd/AdminListPageAdd'))
const UserContactList = React.lazy(() => import('./pages/userContactList/userContactList'))
const UserContactListView = React.lazy(() => import('./pages/userContactListView/userContactListView'))
const UserContactListAdd = React.lazy(() => import('./pages/userContactList/userContactListAdd/userContactListAdd'))
const UserContactLabelScreen = React.lazy(() => import('./pages/userContactLabelScreen/userContactLabelScreen'))
const UserContactLabelScreenAdd = React.lazy(() => import('./pages/userContactLabelScreen/userContactLabelScreenAdd/userContactLabelScreenAdd'))
const UserResetPassword = React.lazy(() => import('./pages/userResetPassword/userResetPassword'))
const UserContactListById = React.lazy(() => import('./pages/userContactList/userContactList'))
const ContactProfileList = React.lazy(() => import('./pages/profile/contactProfileList/contactProfileList'))
const ContactProfileAdd = React.lazy(()=> import('./pages/profile/contactProfileAdd/contactProfileAdd'))

function App() {
  const { messageNotify } = useAppSelector((state) => state.alertReducer);

  return (
    <div className="App">
      {messageNotify && <CustomAlert />}
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path="*" element={<NotFound />} />
          <Route path={PATH.NOT_FOUND} element={<NotFound />} />
          <Route element={<Header />}>
            <Route path={PATH.DASHBOARD} element={<Home />} />
            <Route path={PATH.USER_LIST} element={<UserCreation />} />
            <Route path={PATH.USER_TYPE} element={<UserCreationAdd />} />
            <Route path={PATH.USER_LIST_PAGE} element={<UserListPage />} />
            <Route path={PATH.USER_LIST_PAGE_ADD} element={<UserListPageAdd />} />
            <Route path={PATH.USER_LIST_PAGE_TYPE} element={<UserListPageAdd />} />
            <Route path={PATH.USER_CONTACT_LIST} element={<UserContactList/>} />
            <Route path={PATH.USER_CONTACT_LIST_VIEW} element={<UserContactListView/>} />
            <Route path={PATH.USER_CONTACT_LIST_ADD} element={<UserContactListAdd/>} />
            <Route path={PATH.USER_CONTACT_LIST_TYPE} element={<UserContactListAdd/>} />
            <Route path={PATH.USER_CONTACT_LABEL_SCREEN} element={<UserContactLabelScreen/>} />
            <Route path={PATH.USER_CONTACT_LABEL_SCREEN_ADD} element={<UserContactLabelScreenAdd/>} />
            <Route path={PATH.USER_CONTACT_LABEL_TYPE} element={<UserContactLabelScreenAdd/>} />
            <Route path={PATH.USER_RESET_PASSWORD} element={<UserResetPassword/>} />
            <Route path={PATH.USER_CONTACT_LIST_BY_ID} element={<UserContactListById/>} />
            <Route path={PATH.CONTACT_PROFILE_LIST} element={<ContactProfileList/>} />
            <Route path={PATH.CONTACT_PROFILE_ADD} element={<ContactProfileAdd/>} />
            <Route path={PATH.CONTACT_PROFILE_TYPE} element={<ContactProfileAdd/>} />

          </Route>
        </Routes>
      </Suspense>
    </div>
  );
}
export default App;

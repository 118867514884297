export const urlConstants = {
    LOGIN: '/auth/login', // User Login
    ADMIN_LOGIN: '/auth/admin-login', // Admin Login

    USERLIST: '/users',
    ADMIN_USER_LIST: '/admin',
    ADMIN_USER_CONTACT: '/contacts',
    USER_DROP_DOWN: '/users/dropdown',
    CONTACT_LABEL: '/label',
    CONTACT_LABEL_DROPDOWN: '/label/dropdown',

    FORGOT_PASSWORD_SEND_OTP: '/admin/forgot-password/send-otp',
    FORGOT_PASSWORD_VERIFY_OTP: '/admin/forgot-password/verify-otp',
    FORGOT_PASSWORD_RESET_PASSWORD: '/admin/forgot-password/reset-password',

    CONTACT_PROFILE: '/profile-type',
    CONTACT_PROFIEL_DROPDOWN: '/profile-type/dropdown',

    CONTACT_LOCATION: '/contacts/locations',

    ADMIN_DASHBOARD: '/admin/dashboard',

    USER_PASSWORD_CHANGE: '/users/change-password',

    CONTACT_VISIBILITY: '/visibility-types'
}